<template>
  <div ref="swiper" class="swiper" :class="extraClass">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <slot></slot>
    </div>

    <!-- If we need pagination -->
    <slot name="pagination">
      <div class="swiper-pagination" v-if="hasPagination"></div>
    </slot>

    <!-- If we need navigation buttons -->
    <slot name="navigation">
      <div class="swiper-button-prev" v-if="hasNavigation"></div>
      <div class="swiper-button-next" v-if="hasNavigation"></div>
    </slot>

    <!-- If we need scrollbar -->
    <div class="swiper-scrollbar" v-if="hasScrollbar"></div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Zoom, Lazy, Keyboard, FreeMode, Autoplay } from "swiper"

export default {
  name: "SwiperSlider",
  emits: ['swiper-init', 'slide-change'],
  data() {
    return {
      instance: null
    }
  },
  props: {
    page: { type: Number, default: 1 },
    extraClass: { type: String, default: '' },
    slidesPerView: { type: [Number, String], default: 1 },
    slidesPerGroup: { type: Number, default: 1 },
    spaceBetween: { type: Number, default: 0 },
    breakpoints: { type: Object, required: false },
    hasPagination: { type: Boolean, default: false },
    hasNavigation: { type: Boolean, default: false },
    hasScrollbar: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    selectedIndex: { type: Number, default: 0 },
    zoom: { type: Boolean, default: false },
    lazy: { type: Boolean, default: false },
    keyboard: { type: Boolean, default: true },
    cssMode: { type: Boolean, default: false },
    freeMode: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: false },
    simulateTouch: { type: Boolean, default: false },
    grabCursor: { type: Boolean, default: false },
    autoHeight: { type: Boolean, default: false }
  },
  watch: {
    page() {
      this.update()
    }
  },
  mounted() {
    this.instance = this.init()
  },
  beforeDestroy() {
    this.instance.destroy()
  },
  methods: {
    init() {
      const modules = this.getModules()
      const events = this.getEvents()
      const behaviour = this.getBehaviour()
      const uiElements = this.getUiElements()

      const options = {
        ...modules,
        ...behaviour,
        ...events,
        ...uiElements
      }

      return new Swiper(this.$refs.swiper, options)
    },
    getModules() {
      const allModules = [
        { module: Navigation, enabled: this.hasNavigation },
        { module: Pagination, enabled: this.hasPagination },
        { module: Zoom, enabled: this.zoom },
        { module: Lazy, enabled: this.lazy },
        { module: Keyboard, enabled: this.keyboard },
        { module: FreeMode, enabled: this.freeMode },
        { module: Autoplay, enabled: this.autoplay }
      ]

      return {
        modules: allModules.filter(m => m.enabled).map(m => m.module)
      }
    },
    getEvents() {
      let firstClick = true
      return {
        on: {
          init: () => this.$emit('swiper-init'),
          slideChange: (swiper) => {
            let index = swiper.realIndex
            //Due to a swiper strange calculation (bug?) the first realIndex is wrong (+1) when option loop===true
            if (firstClick && this.loop) index -= 1
            firstClick = false
            this.$emit('slide-change', index, swiper)
          }
        }
      }
    },
    getBehaviour() {
      return {
        simulateTouch: this.simulateTouch,
        slidesPerView: this.slidesPerView,
        spaceBetween: this.spaceBetween,
        slidesPerGroup: this.slidesPerGroup,
        cssMode: this.cssMode,
        freeMode: this.freeMode,
        grabCursor: this.grabCursor,
        breakpoints: this.breakpoints,
        lazy: this.lazy && { loadPrevNext: true },
        loop: this.loop,
        initialSlide: this.selectedIndex,
        zoom: this.zoom,
        keyboard: this.keyboard,
        autoplay: this.autoplay && { delay: 2500, disableOnInteraction: true },
        autoHeight: this.autoHeight
      }
    },
    getUiElements() {
      return {
        ...(this.hasPagination && { pagination: { el: '.swiper-pagination' } }),
        ...(this.hasNavigation && { navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' } }),
        ...(this.hasScrollbar && { scrollbar: { el: '.swiper-scrollbar' } })
      }
    },
    update() {
      this.instance.update()
    }
  }
}
</script>